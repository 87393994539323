<template>
  <div class="row">
    <div class="col-12">
      <Widget class="h-auto">
        <template #title>Videos</template>

        <button class="btn btn-block btn-medium" @click="createItem">
          Nuevo Video
        </button>
        <vue-table
          v-if="vTable.headers"
          :values="items"
          :headers="vTable.headers"
          :actions="vTable.actions"
          :options="vTable.options"
          ref="vtable"
          @changed="getItems"
          @onShowItem="onShowItem"
          @onDeleteItem="deleteItem"
        />
      </Widget>
    </div>

    <VideoModal
      v-if="showModal"
      @close="closeModal"
      :item="selItem"
      @saved="onSaveItem"
    />
  </div>
</template>

<script>
import Widget from "@/components/Widget.vue";
import VideoModal from "./VideoModal.vue";

export default {
  components: {
    Widget,
    VideoModal,
  },
  data: () => ({
    vTable: {
      headers: [
        {
          title: "youtube_token",
          mask: "Token",
          sortable: true,
        },
        {
          title: 'name',
          mask: 'Nombre',
          sortable: true,
        },
        {
          title: "created_at",
          mask: "Creado",
          dateFormat: "DD-MM-YYYY HH:mm",
          sortable: true,
        },
        {
          title: "updated_at",
          mask: "Actualizado",
          dateFormat: "DD-MM-YYYY HH:mm",
          sortable: true,
        },
      ],
      actions: [
        {
          buttonClass: "btn-info",
          callback: "onShowItem",
          tooltip: "Mostrar Detalles",
          icon: "fa-solid fa-eye",
        },
        {
          buttonClass: "btn-danger",
          callback: "onDeleteItem",
          tooltip: "Borrar Video",
          icon: "fa-solid fa-trash",
        },
      ],
      options: {
        /// Go to OPTIONS SECTION for explanation
      },
    },
    showModal: false,
    selItem: null,
  }),
  mounted() {
    this.initializeTable();
  },
  computed: {
    items() {
      return this.$store.getters.videos;
    },
  },
  methods: {
    initializeTable() {
      this.$refs.vtable.init();
    },
    closeModal() {
      this.selItem = null;
      this.showModal = false;
    },
    getItems(params) {
      this.$store.dispatch("getVideos", params);
    },
    onShowItem(item) {
      this.showModal = true;
      this.selItem = item;
    },
    createItem() {
      this.showModal = true;
    },
    onSaveItem() {
      this.initializeTable(); // Reset table
    },
    deleteItem(item) {
      this.$alert
        .fireConfirm("Borrar Video", "Esta seguro que desea continuar?")
        .then(() => {
          this.$store.dispatch("deleteVideo", item.video_id);
        });
    },
  },
};
</script>
