<template>
  <vue-modal @close="$emit('close')" ref="modal" size="sm">
    <template #title>Video</template>
    <template #body>
      <FormText
        label="Youtube URL"
        icon="fa-brands fa-youtube"
        @change="onPasteLink"
        v-if="!form.video_id"
      />
      <FormText
        label="Nombre"
        icon="fa-brands fa-youtube"
        v-model="form.name"
      />
      <FormSwitch
        label="Es HOME ??"
        v-model="homeSelector"
      />
      <FormText
        label="Token"
        icon="fa-solid fa-code"
        v-model="form.youtube_token"
        disabled
      />
      <iframe
        v-if="form.youtube_token"
        :src="
          'https://www.youtube.com/embed/' +
          form.youtube_token +
          '?controls=1&loop=1&autoplay=1'
        "
        width="100%"
        height="300"
        frameborder="0"
        allow="accelerometer;
            autoplay;
            modestbranding;
            clipboard-write;
            encrypted-media;"
        allowfullscreen
        class="mt-4"
      />
    </template>
    <template #footer>
      <button class="btn btn-primary" v-if="!form.video_id" @click="saveData">
        Guardar
      </button>
    </template>
  </vue-modal>
</template>
<script>
import FormText from "@/components/Form/FormText.vue";
import FormSwitch from "@/components/Form/FormSwitch.vue";

export default {
  components: {
    FormText,
    FormSwitch,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    homeSelector: false,
    form: {
      youtube_token: null,
    },
  }),
  mounted() {
    if (this.item) {
      this.form = {...this.item};
    }
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    onPasteLink({ target }) {
      if (!target.value) return;

      const urlParams = new URLSearchParams(
        target.value.slice(target.value.indexOf("?"))
      );

      if (!urlParams.get("v")) this.$toast.error("URL invalida");
      else this.form.youtube_token = urlParams.get("v");
    },
    saveData() {
      if (this.homeSelector) {this.form.name = `${this.form.name } HOME`}
      this.$store.dispatch("createVideo", this.form).then(() => {
        this.close();
      });
    },
  },
};
</script>
<style></style>
